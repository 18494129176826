import { useParams } from '@solidjs/router';
import { createEffect, createSignal, onMount, Show } from 'solid-js';
import { Elements } from 'solid-stripe';
import ActivityIndicator from '~/components/common/activity-indicator/ActivityIndicator';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { currency } from '~/global-contexts/utils/number';
import { RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { GetRentalApplicationStatusUseCase } from '~/use-cases/rental-applications/application-steps/getRentalApplicationStatusUseCase';
import { GetRentalApplicationPaymentInfoUseCase } from '~/use-cases/rental-applications/getRentalApplicationPaymentIntentUseCase';
import { StripePaymentFailedUseCase } from '~/use-cases/rental-applications/stripePaymentFailedUseCase';
import { StripePayment } from '../screening/components/StripePayment';

export const PaymentStep = () => {
  const params = useParams();
  const [paymentSucceeded, setPaymenSucceeded] = createSignal<boolean>(false);

  const { execute: getRentalApplicationStatus, isLoading: isLoadingStatus } = useUseCase(GetRentalApplicationStatusUseCase);
  const { execute: getPaymentIntent, isLoading: isLoadingPaymentIntent } = useUseCase(GetRentalApplicationPaymentInfoUseCase);
  const { execute: stripePaymentFailed } = useUseCase(StripePaymentFailedUseCase);
  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);

  onMount(async () => {
    await getPaymentIntent({ id: params.id, password: params.password });
  });

  createEffect(() => {
    if (presentableRentalApplication()?.isPaid || paymentSucceeded()) {
      setTimeout(() => {
        getRentalApplicationStatus({ id: params.id, password: params.password });
      }, 1000);
    }
  });

  return (
    <Show
      when={
        !isLoadingPaymentIntent() &&
        !isLoadingStatus() &&
        !paymentSucceeded() &&
        presentableRentalApplication()?.paymentInfo?.account &&
        presentableRentalApplication()?.paymentInfo?.clientSecret
      }
      fallback={
        <div class="flex h-section3">
          <ActivityIndicator />
        </div>
      }>
      <Elements
        stripe={presentableRentalApplication()?.paymentInfo?.account || null}
        clientSecret={presentableRentalApplication()?.paymentInfo?.clientSecret}>
        <StripePayment
          amount={currency(presentableRentalApplication()?.paymentInfo?.totalAmount)}
          onSuccess={() => setPaymenSucceeded(true)}
          onError={stripePaymentFailed}
        />
      </Elements>
    </Show>
  );
};
