export const getStripeKey = () => {
  if (import.meta.env.DEV) {
    return 'pk_test_51ORgoZCnTWH4qW15EHjcFU65qk8uJFPnOvbRW1MogCiK54GDrYl9YSPykpDD6UK7bq2r0CskFY573gHQdMEyWpWg006rJcf6tW';
  }
  const hostname = window.location.hostname;
  if (hostname.includes('test')) {
    return 'pk_test_51ORgoZCnTWH4qW15EHjcFU65qk8uJFPnOvbRW1MogCiK54GDrYl9YSPykpDD6UK7bq2r0CskFY573gHQdMEyWpWg006rJcf6tW';
  }
  return 'pk_live_51ORgoZCnTWH4qW15GuxPS5qD5UJhKtF1BrrwE1rxdmYewxdL5vjoHSX55KMK7975w2eGqTKxuBNRMNEemWSkZnR900MpzGxvk3';
};
