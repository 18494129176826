import IconPaymentProcessing from '~/assets/icons/paymentProcessing.gif';
import { PAYMENT_PROCESSING, PAYMENT_PROCESSING_BLURB } from '~/assets/strings';
import { useLocalization } from '~/hooks/useLocalization';

export const ProcessingStripePayment = () => {
  const { t } = useLocalization();

  return (
    <div class="absolute inset-0 flex w-full flex-col items-center  justify-center bg-white text-center">
      <img src={IconPaymentProcessing} />
      <h1 class="w-3/5 text-lg font-semibold text-text-level02">{t(PAYMENT_PROCESSING)}</h1>
      <p class="text-sm font-normal text-text-level03">{t(PAYMENT_PROCESSING_BLURB)}</p>
    </div>
  );
};
