import { PaymentStep } from '~/pages/rental-application/steps/PaymentStep';

const RentalApplicationScreeningPage = () => {
  return (
    <div class="relative flex h-full grow flex-col">
      <PaymentStep />
    </div>
  );
};

export default RentalApplicationScreeningPage;
