import { loadStripe } from '@stripe/stripe-js';
import { rentalApplicationGateway } from '~/gateways/rentalApplicationGateway';
import { RentalApplicationCredentials } from '~/state/mainAppState';
import { getStripeKey } from '~/utils/stripeKey';
import { MagicUseCase } from '../magicUseCase';
import { GetRentalApplicationUseCase } from './getRentalApplicationUseCase';
import { LockRentalApplicationUseCase } from './lockRentalApplicationUseCase';

export class GetRentalApplicationPaymentInfoUseCase extends MagicUseCase {
  protected async runLogic(credentials: RentalApplicationCredentials) {
    await new GetRentalApplicationUseCase().execute(credentials);
    if (this.getState().user.rentalApplication.isPaid || !this.getState().user.rentalApplication.application?.requiresPayment) {
      return;
    }
    if (this.getState().user.rentalApplication.application?.isDraft) {
      await new LockRentalApplicationUseCase().execute();
    }
    const stripeInfo = await rentalApplicationGateway.getPaymentIntentForRentalApplication(credentials);
    if (!stripeInfo.account && stripeInfo.stripeAccountId) {
      stripeInfo.account = (await loadStripe(getStripeKey(), { stripeAccount: stripeInfo.stripeAccountId })) || undefined;
    }
    this.getState().user.rentalApplication.stripe = stripeInfo;
  }
}
